<template>
  <div id="garantía" class="guarantee">
    <h2 class="h3">GARANTÍA</h2>
    <img src="@/assets/img/logo.svg" class="logo tablet mobile" width="20" />
    <div class="content container">
      <img src="@/assets/img/tube.png" alt="tube" class="tube" width="244" />
      <div class="text-content">
        <p class="text1">
        ¿Ofrece Fast Slim una garantía de devolución del dinero? Sí. Si usted no está satisfecho al 100 % con los resultados, simplemente devuelvan el producto. Si no consigues perdida de peso, ni disfrutas de quema de grasa segura y natural, simplemente devuelve el producto en un plazo de 1 año y obtendrás un reembolso completo, menos los gastos de envío, sin hacer preguntas. ¿Por qué Fast Slim tiene tanta garantía? Queremos que te sientas seguro al probar Fast Slim por primera vez. También queremos que experimentes los sorprendentes resultados que otros clientes como tú han experimentado. Por eso te ofrecemos una garantía de devolución del dinero de 365 días, para que puedas experimentar realmente nuestro producto sin ningún riesgo. Pedirlo es muy sencillo y no hay razón para no hacerlo. Si no estás satisfecho por cualquier motivo, devuelve las botellas de Fast Slim, aunque estén vacías, en un plazo de 1 año. Confía en Fast Slim y notarás los resultados en poco tiempo!
	</p>
      </div>
    </div>

  </div>

</template>

<script setup>

</script>

<style scoped lang="scss">
.guarantee {
  background-image: url("@/assets/img/background.png");
  background-position: center;
  background-size: cover;
  padding: 20px;
  .text1 {
    margin-bottom: 60px;
    color: white;
    font-size: 16px;

    @media screen and (min-width: 768px) {
      width: 59%;
      margin: 0 auto 60px auto;
    }
    @media screen and (min-width: 1440px) {
      text-align: left;
      width: 91.8%;
      display: block;
      margin: 0 auto 50px 0;
    }
  }

.h3 {
  text-align: center;
  color: white;
  margin-top: 50px;
}



  .logo {
    display: block;
    margin: 0 auto 20px;
  }
  .tube {
    display: block;
    margin: 0 auto 50px auto;
    @media screen and (min-width: 768px) {
      margin: 0 auto 26px auto;
    }
  }

.footer {
  width: 100%;
  .img {
    margin-top: 90px;
    @media screen and (min-width: 768px) {
      width: 241px;
    }
  }
  .logo {
    margin: 50px auto 30px auto;
    @media screen and (min-width: 1440px) {
      margin: 20px auto;
    }
  }
  @media screen and (min-width: 768px) {
    border-top: 1px solid white;
  }

  .subtext {
    color: #fff;
    font-size: 16px;
    font-style: normal;
    text-align: center;
    font-weight: 500;
    line-height: normal;
    text-decoration-line: underline;
    @media screen and (min-width: 1440px) {
      margin-bottom: 20px;
    }
  }
}



  .items-list {
    width: 89%;
    margin: 0 auto 44px auto;
    @media screen and (min-width: 768px) {
      width: 59%;
      margin: 0 auto 107px auto;
    }
    @media screen and (min-width: 1440px) {
      width: 100%;
      margin: 0;
    }
    .text {
      color: $white;
      clear: left;
      padding: 0.5rem 0;
      margin-bottom: 20px;

      @media screen and (min-width: 768px) {
        font-size: 22px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
      }
    }
    ::before {
      content: "";
      height: 28px;
      width: 20px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: 100%;
      background: url("@/assets/img/list-icon.svg");
      background-size: cover;
      background-position: center;
      padding-left: 28px;
      margin-right: 8px;
    }
  }
  @media screen and (min-width: 768px) {
    background-image: url("../../assets/img/background-tablet.png");
  }
  @media screen and (min-width: 1440px) {
    background-image: url("../../assets/img/bgDesk.png");
  }
  .content {
    @media screen and (min-width: 1440px) {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      margin-top: 100px;
      margin-bottom: 60px;
      .text-content {
        width: 54%;
      }
      .tube {
        width: 450px;
        margin: 0 0 0 auto;
      }
    }
  }
}
</style>
